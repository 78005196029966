<template>
	<div>
		<jy-query ref="form" :model="attendanceForm">
			<jy-query-item label="开始时间:" prop="startTime">
				<el-date-picker
					v-model="attendanceForm.startTime"
					value-format="yyyy-MM-dd"
					:picker-options="startTime"
					type="date"
					placeholder="选择日期"
				></el-date-picker>
			</jy-query-item>
			<jy-query-item label="结束时间:" prop="endTime">
				<el-date-picker
					v-model="attendanceForm.endTime"
					value-format="yyyy-MM-dd"
					:picker-options="endTime"
					type="date"
					placeholder="选择日期"
				></el-date-picker>
			</jy-query-item>
			<jy-query-item prop="routeId" label="线路">
				<el-select v-model="attendanceForm.routeIds" multiple>
					<el-option v-for="one in routeOptions" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="驾驶员:" prop="routeId">
				<el-select v-model="attendanceForm.driverIds" placeholder="请选择驾驶员" v-loadmore="loadMore" multiple>
					<el-option v-for="item in driverOptions" :key="item.driverId" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="routeId" label="员工工号">
				<el-input v-model="attendanceForm.employeeId"></el-input>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
			</template>
			<template slot="operate">
				<el-button type="primary" @click="importData">导入</el-button>
			</template>
		</jy-query>
		<jy-table max-height="595" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column prop="driverNa" label="驾驶员"></jy-table-column>
			<jy-table-column prop="orgNa" label="线路"></jy-table-column>
			<jy-table-column prop="employeeId" label="员工工号"></jy-table-column>
			<jy-table-column label="考勤类型" min-width="160">
				<template slot-scope="scope">{{ scope.row.aType | aType }}</template>
			</jy-table-column>
			<jy-table-column prop="plateNum" label="打卡车辆"></jy-table-column>
			<jy-table-column prop="rTime" label="考勤时间"></jy-table-column>
			<jy-table-column prop="routeNo" label="线路编号"></jy-table-column>
			<jy-table-column label="考勤结果">
				<template slot-scope="scope">{{ scope.row.aType | res }}</template>
			</jy-table-column>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		>
		</jy-pagination>
		<jy-import
			ref="jyImport"
			@downloadTemplate="downloadTemplate"
			@getElxData="getElxData"
			@confirm="confirm"
			:headers="importInfo.uploadHeaders"
			:action="importInfo.action"
			:successLength="importInfo.correctCount"
			:errList="importInfo.listError"
			:status.sync="importInfo.importStatus"
		></jy-import>
	</div>
</template>
<script>
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			attendanceForm: {
				startTime: "",
				endTime: "",
				routeIds: [],
				driverIds: [],
				employeeId: ""
			},
			importInfo: {
				//导入
				uploadHeaders: {
					Token: this.$util.getStor("token")
				},
				importStatus: 1,
				action: "ischedule/attendance/detail/importAttendanceCach",
				correctCount: "",
				errList: "",
				importId: ""
			},
			dataList: [],
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			routeOptions: [],
			driverOptions: [],
			startTime: {
				disabledDate: time => {
					if (this.attendanceForm.endTime) {
						return time.getTime() >= new Date(this.attendanceForm.endTime).getTime();
					} else {
						return time.getTime() > Date.now();
					}
				}
			},
			endTime: {
				disabledDate: time => {
					if (this.attendanceForm.startTime) {
						return time.getTime() > Date.now() || time.getTime() < new Date(this.attendanceForm.startTime).getTime() - 8.64e7;
					} else {
						return time.getTime() > Date.now();
					}
				}
			}
		};
	},
	filters: {
		aType(val) {
			if (val == 1) {
				return "上班";
			} else if (val == 2) {
				return "下班";
			} else if (val == 3) {
				return "签到";
			} else if (val == 4) {
				return "签退";
			} else if (val == 5) {
				return "例检";
			}
		},
		res(val) {
			if (val == 1) {
				return "上班成功";
			} else if (val == 2) {
				return "下班成功";
			} else if (val == 3) {
				return "签到成功";
			} else if (val == 4) {
				return "签退成功";
			} else if (val == 5) {
				return "例检成功";
			}
		}
	},
	async created() {
		await this.getRouteList();
		this.getDrivers();
		this.getList();
	},
	mixins: [btnMixins],
	methods: {
		importData() {
			this.$refs.jyImport.init();
			console.log("导入", this.importInfo.uploadHeaders);
		},
		async getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			await this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		downloadTemplate() {
			let url = "attendance/detail/downloadTemplate";
			this.$http.post(url, {}, { type: "format", isExcel: true }).then(res => {
				this.$util.funDownload(res.data, "考勤模板");
			});
		},
		getElxData(data) {
			console.log(data.detail, "导入数据");
			// this.importId = data.detail.importId;
			const importId = data.detail.importId;
			const url = "attendance/detail/importAttendanceCheck";
			const params = { importId };
			this.$http.post(url, params).then(data => {
				const { detail } = data;
				const { listError, correctCount, errorCount } = detail;
				this.importInfo = { ...this.importInfo, listError, correctCount, errorCount, importId };
			});
		},
		confirm() {
			let params = {
				importId: this.importInfo.importId
			};
			let url = "attendance/detail/importAttendanceData";
			this.$http.post(url, params).then(
				data => {
					if (data.code == 0) {
						this.importInfo.importStatus = 1;
						this.oncheck();
					}
				},
				() => {
					this.importInfo.importStatus = -1;
				}
			);
		},
		getList() {
			let option = {
				...this.attendanceForm,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "attendance/detail/queryPage";
			this.$http.post(url, option).then(({ detail }) => {
				this.dataList = detail.list;
				this.total = detail.total;
			});
		},
		getDrivers() {
			let option = {
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/base/driver/queryPage";
			this.$http.post(url, option).then(({ detail }) => {
				console.log(detail);
				let list = detail.list.map(item => {
					return {
						label: item.driverInfo.cName,
						value: item.driverInfo.driverId
					};
				});
				this.driverOptions = list;
			});
		},
		// 查询表格
		oncheck() {
			this.getList();
		},
		// 重置
		resetForm() {
			(this.attendanceForm.startTime = ""),
				(this.attendanceForm.endTime = ""),
				(this.attendanceForm.routeIds = []),
				(this.attendanceForm.driverIds = []),
				(this.attendanceForm.employeeId = "");
			this.pageIndex = 1;
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getList();
		},
		loadMore() {
			console.log(111);
			this.pageIndex += 1;
			let option = {
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/base/driver/queryPage";
			this.$http.post(url, option).then(({ detail }) => {
				if (detail.list.length > 0) {
					detail.list.map(item => {
						let list = {
							label: item.driverInfo.cName,
							value: item.driverInfo.driverId
						};
						this.driverOptions.push(list);
					});
				}
			});
		}
	}
};
</script>
total
<style scoped>
.total {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	height: 54px;
	background: inherit;
	background-color: rgba(215, 215, 215, 1);
	padding: 20px;
	font-size: initial;
}
</style>
